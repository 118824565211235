import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as DocsIcon } from 'gatsby-theme-apollo-docs/src/assets/docs.svg';

const Wrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    fontSize: 17,
    textTransform: 'uppercase'
});

const StyledDocsIcon = styled(DocsIcon)({
    marginLeft: 10,
    height: 20,
});

export default function Logo() {
    return (
        <Wrapper>
            Scrolly Telling
            <StyledDocsIcon />
        </Wrapper>
    );
}
